import { Box, Typography, useMediaQuery } from "@mui/material";
import StyledButton from "../StyledButton";
import { t } from '@lingui/macro';
import { useLingui } from "@lingui/react";

const WelcomeSection: React.FC<{ id: string }> = (props) => {
    const isMobile = useMediaQuery('(max-width: 900px)');
    const { i18n } = useLingui();
    const { id } = props;
    const textColor = '#000000'

    return <div id={id}>
        <Box pt={isMobile ? '30px' : '50px'} pb={'40px'} display={'flex'} flexDirection={'column'} bgcolor={'#F5F5F7'} alignItems={'center'}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                {isMobile ?
                    <img src="home-small.png" width={'76.41px'} height={'76.41'} /> :
                    <img src="home-big.png" width={'160.16px'} height={'160.16px'} />}
            </Box>
          
            <Typography pt={isMobile ? '15px' : '40px'} align={'center'} maxWidth={isMobile ? '90%' : '70%'} fontSize={isMobile ? 50 : 76} fontWeight='600' lineHeight={'95%'} color={textColor}>{t`Create. Serve. Succeed.`}</Typography>
            <Typography pt={isMobile ? '15px' : '20px'} align={'center'} maxWidth={isMobile ? '90%' : '70%'} fontSize={isMobile ? 20 : 36} fontWeight='700' color={textColor}>{t`One platform to create, serve, and elevate your dining experience.`}</Typography>
          
            <Box pt={'20px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <StyledButton size={'large'} sx={{ bgcolor: '#000000' }} variant='contained' onClick={() => {
                    // document.getElementById("pricing_section")!.scrollIntoView();
                }}>
                    <Typography fontSize={'14px'} fontWeight='700'>{t`Get KlikMenu free`}</Typography>
                </StyledButton>
            </Box>
          
            <Typography pt={'70px'} align={'center'} fontSize={20} fontWeight='600' lineHeight={'95%'} color={'#999999'}>{t`Trusted by:`}</Typography>
           
            {!isMobile &&
                <Box pt={'20px'} gap={'50px'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                    <Box><img src="crocodiles-big.png" width={'139.02px'} height={'95.34px'} /></Box>
                    <Box><img src="alexandergrill-big.png" width={'233.76px'} height={'54.95px'} /></Box>
                    <Box><img src="kingpoke-big.png" width={'141.8px'} height={'67.83px'} /></Box>
                </Box>
            }

            {isMobile &&
                <Box pt={'20px'} gap={'10px'} display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <Box><img src="crocodiles-small.png" width={'84.15px'} height={'57.71px'} /></Box>
                    <Box><img src="alexandergrill-small.png" width={'132.62px'} height={'31.17px'} /></Box>
                    <Box><img src="kingpoke-small.png" width={'99.86px'} height={'47.76px'} /></Box>
                </Box>
            }
        </Box>
    </div>
}

export default WelcomeSection;