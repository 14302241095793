import './App.css';
import { Container } from '@mui/material';
import appRoutes from './utils/routes';
import StartScreen from './Screens/StartScreen';
import MainScreen from './Screens/MainScreen';
import NotFoundScreen from './Screens/NotFoundScreen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Container maxWidth={false} disableGutters>
      <BrowserRouter>
        <Routes>
          <Route path={appRoutes.start} Component={StartScreen} />
          <Route path={appRoutes.app} Component={MainScreen} />
          <Route path="*" Component={NotFoundScreen} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

export default App;
