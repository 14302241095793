import { useTheme } from "@emotion/react";
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { useLingui } from "@lingui/react";
import { t } from '@lingui/macro';

interface ISection {
    id: string;
    name: string;
}
const NavifationBar: React.FC = () => {
    const { i18n } = useLingui();

    const sections: ISection[] = [{ id: 'welcome_section', name: t`Home` }, { id: 'pricing_section', name: t`Pricing` }, { id: 'contact_section', name: t`Contact` }];

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const theme = useTheme();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return <AppBar elevation={0} position="sticky" sx={{ bgcolor: "#00AEE9" }}>
        <div id='nav-bar'>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Typography // small title
                        onClick={() => {
                            const pricing = document.getElementById('welcome_section')!
                            const navbar = document.getElementById('nav-bar')!

                            var rect = pricing.getBoundingClientRect();
                            var navbarrect = navbar.getBoundingClientRect();

                            window.scrollTo({ top: rect.y + window.scrollY - navbarrect.height, left: 0, behavior: 'smooth' })

                        }}
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontSize: 20,
                            fontWeight: 900,
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
                        KlikMenu.be
                    </Typography>

                    <Typography // large title
                        onClick={() => {
                            const pricing = document.getElementById('welcome_section')!
                            const navbar = document.getElementById('nav-bar')!

                            var rect = pricing.getBoundingClientRect();
                            var navbarrect = navbar.getBoundingClientRect();

                            window.scrollTo({ top: rect.y + window.scrollY - navbarrect.height, left: 0, behavior: 'smooth' })
                        }}
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            fontSize: 25,
                            display: { xs: 'none', md: 'flex' },
                            fontWeight: 900,
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
                        KlikMenu.be
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {sections.map((section) => (
                            <Button
                                key={section.id}
                                onClick={() => {
                                    const pricing = document.getElementById(section.id)!
                                    const navbar = document.getElementById('nav-bar')!

                                    var rect = pricing.getBoundingClientRect();
                                    var navbarrect = navbar.getBoundingClientRect();

                                    window.scrollTo({ top: rect.y + window.scrollY - navbarrect.height, left: 0, behavior: 'smooth' })
                                    handleCloseNavMenu()
                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Typography fontWeight={500}>
                                    {section.name}
                                </Typography>

                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu // Burger Menu when small screen
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            {sections.map((section) => (
                                <MenuItem key={section.id} onClick={() => {

                                    const pricing = document.getElementById(section.id)!
                                    const navbar = document.getElementById('nav-bar')!

                                    var rect = pricing.getBoundingClientRect();
                                    var navbarrect = navbar.getBoundingClientRect();

                                    window.scrollTo({ top: rect.y + window.scrollY - navbarrect.height, left: 0, behavior: 'smooth' })

                                    handleCloseNavMenu()
                                }}>
                                    <Typography sx={{ textAlign: 'center' }}>{section.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
            <Box pb={'3px'} sx={{ bgcolor: "#ffffff" }} />
        </div>
    </AppBar>
}

export default NavifationBar;