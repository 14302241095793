import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import appRoutes from "../utils/routes";

const NotFoundScreen: React.FC = () => {
    const navigate = useNavigate()
    useEffect(() => { navigate(appRoutes.start) }, [navigate])
    return <></>
}

export default NotFoundScreen;
