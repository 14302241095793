import { Box, FormControl, FormHelperText, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { t } from '@lingui/macro';
import { useState } from "react";
import supportedLocales, { dynamicActivate } from "../../utils/locales";
import { useLingui } from "@lingui/react";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { isDevMode } from '../../utils';

// require('dayjs/locale/de')
// require('dayjs/locale/nl')
// require('dayjs/locale/fr')
// require('dayjs/locale/uk')

const BottomSection: React.FC<{ id: string }> = (props) => {
    const { i18n } = useLingui();
    const { id } = props;
    const [currentLocale, setCurrentLocale] = useState<string>(i18n.locale)

    async function handleChange(event: SelectChangeEvent) {
        let domain: string | undefined;

        if (isDevMode()) {
            domain = undefined;  // TODO: future stage subdomain goes here
        } else {
            let host = window.location.host;

            if (host.split('.')[0] === "www") {
                host = window.location.host.slice(4);
            } 
            domain = host;
        }

        const locale = event.target.value;
        await dynamicActivate(locale); //TODO: move locale management to locale service
        //dispatch(userSlice.actions.setLocale(locale))
        //api.changeApiLocale(locale);
        setCurrentLocale(locale);
        Cookies.set('userLocale', locale, { expires: dayjs().add(60, 'day').toDate(), domain: domain })
        //dayjs.locale(locale)
        //user.isVerified && api.updateUserInfo({ ...user, preferedLocale: locale })
    }


    return <div id={id}>
     <Box display={'flex'} flexDirection={'column'} p={'30px'} justifyItems={'space-between'} alignItems={'center'} bgcolor={'#ffffff'}>
        <Box display={'flex-inline'} flexDirection={'column'} width={'100%'}>
            <Box>
                <IconButton size='large' color="primary">
                    <InstagramIcon fontSize='inherit' />
                </IconButton>
                <IconButton size='large' color="primary">
                    <FacebookIcon fontSize='inherit' />
                </IconButton>
            </Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>

                <Select
                    value={currentLocale}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleChange}
                >
                    {Object.entries(supportedLocales).map(([locale, label]) =>
                        <MenuItem value={locale}>{label}</MenuItem>
                    )}
                </Select>

                <FormHelperText>{t`Choose language`}</FormHelperText>
            </FormControl>
        </Box>
        <Typography pt={'50px'} align='center' fontSize={20} fontWeight='600' color={'#999999'}>{t`© 2024 KlikMenu, Inc.`}</Typography>
    </Box>
    </div>
}

export default BottomSection;