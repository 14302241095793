import { i18n } from "@lingui/core";

const supportedLocales = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    nl: 'Nederlands',
    uk: 'Українська'
};

export async function dynamicActivate(locale: string) {
    const { messages } = await import(`../locales/${locale}/messages`);
    i18n.load(locale, messages);
    i18n.activate(locale);
}

export default supportedLocales;
