import React, { useEffect, useState } from 'react';
import { Box, Fab, Fade, useScrollTrigger } from '@mui/material';
import BottomSection from '../components/MainScreen/BottomSection'
import WelcomeSection from '../components/MainScreen/WelcomeSection'
import PricingSection from '../components/MainScreen/PricingSection'
import NavigationBar from '../components/MainScreen/NavigationBar'
import Cookies from 'js-cookie';
import supportedLocales, { dynamicActivate } from '../utils/locales';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScrollTop() {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const pricing = document.getElementById('welcome_section')!
        const navbar = document.getElementById('nav-bar')!

        var rect = pricing.getBoundingClientRect();
        var navbarrect = navbar.getBoundingClientRect();

        window.scrollTo({top: rect.y + window.scrollY-navbarrect.height, left: 0, behavior: 'smooth'})
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Fade>
    );
}

const MainScreen: React.FC = () => {

    const [isAppReady, setIsAppReady] = useState<boolean>(false);

    useEffect(() => {
        run()
    })

    async function run() {
        const locale = Cookies.get('userLocale') || window.navigator.language.substring(0, 2);
        let localeforApi: string | undefined;
        if (Object.keys(supportedLocales).includes(locale)) {
            localeforApi = locale
        }
        localeforApi && await dynamicActivate(localeforApi)
        setIsAppReady(true)
    }

    return <div>
        {isAppReady &&
            <Box sx={{ display: 'flex', flexDirection: 'column', height: "100%", width: "100vw", minHeight: '100vh', bgcolor: "#00AEE9" }}>
                <NavigationBar />
                <WelcomeSection id='welcome_section' />
                <PricingSection id='pricing_section' />
                <Box p={'100px'} />
                <BottomSection id='contact_section' />
                <ScrollTop />
            </Box>
        }
    </div>
}

export default MainScreen;