import Loader from "../components/Loader";
import Cookies from 'js-cookie';
import supportedLocales, { dynamicActivate } from '../utils/locales';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../utils/routes';
import { useEffect, useState } from "react";

const StartScreen = () => {
    const navigate = useNavigate();
    const [isAppReady, setIsAppReady] = useState<boolean>(true);

    useEffect(() => {
        isAppReady && navigate(appRoutes.app)
    })
    return <Loader />
}

export default StartScreen;