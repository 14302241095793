import { Box, Paper, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import StyledButton from "../StyledButton";
import { t } from '@lingui/macro';
import { useLingui } from "@lingui/react";
import { Tune } from "@mui/icons-material";

type TPackageItem = {
    packageName: string,
    icon: string,
    isFree: boolean,
    isPackagePopular: boolean,
    packagePrice: string,
    discountedPackagePrice: string,
    packageDescription: string[],
    discountDescription: string,
    additionalDescription: string
}

const PricingSection: React.FC<{ id: string }> = (props) => {

    const { id } = props;
    const { i18n } = useLingui();

    const [paymentPeriod, setPaymentPeriod] = useState('monthly');
    const isMonthly = paymentPeriod == 'monthly'
    const isMobile = useMediaQuery('(max-width: 900px)');

    const free = { packageName: t`Free`, icon: '💸', isFree: true, isPackagePopular: false, packagePrice: '0€', discountedPackagePrice: '0€', packageDescription: [t`QR-menu for each table.`, t`Online menu for visitors.`] }
    const basic = { packageName: t`Basic`, icon: '🧩', isFree: false, isPackagePopular: false, packagePrice: '12€', discountedPackagePrice: '10€', packageDescription: [t`QR-menu for each table.`, t`Online menu for visitors.`, t`Unlimited menu items.`], discountDescription: t`save 24€ or 3 month for free` }
    const pro = { packageName: t`Pro`, icon: '⚡', isFree: false, isPackagePopular: true, packagePrice: '120€', discountedPackagePrice: '100€', packageDescription: [t`Admin application.`, t`Takeout.`, t`Delivery.`, t`Dine-in.`], additionalDescription: t`Everything in Basic +`, discountDescription: t`save 240€ or 3 month for free` }
    const allInclusive = { packageName: t`All inclusive`, icon: '💎', isFree: false, isPackagePopular: false, packagePrice: '240€', discountedPackagePrice: '200€', packageDescription: [t`iPhone application.`, t`Android application.`], additionalDescription: t`Everything in Pro +`, discountDescription: t`Save 480€ — that's like getting 3 months free!` }

    const packages = [free, basic, pro, allInclusive] as TPackageItem[]
    const handlePaymentPeriodChange = (
        event: React.MouseEvent<HTMLElement>,
        newPaymentPeriod: string,
    ) => {
        if (newPaymentPeriod !== null) {
            setPaymentPeriod(newPaymentPeriod)
        }
    };

    return <div id={id}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box pt={5} />
            <Typography fontSize={isMobile ? 32 : 50} fontWeight='700' color={"#ffffff"} sx={{ textAlign: 'center', p: "0px 40px 0px 40px" }}>{t`The Price is Right, Every Time.`}</Typography>
            <Box pb={5} />
            <Box display={'flex'} pl={'20px'} pr={'20px'} justifyContent={'center'}>
                <ToggleButtonGroup
                    value={paymentPeriod}
                    exclusive
                    color='success'
                    onChange={handlePaymentPeriodChange}
                    aria-label="Platform"
                    sx={{ bgcolor: '#ffffff', borderRadius: '38px' }}
                >
                    <ToggleButton value="monthly" sx={{ textTransform: 'none', borderRadius: '38px' }}>{t`Pay monthly`}</ToggleButton>
                    <ToggleButton value="yearly" sx={{ textTransform: 'none', borderRadius: '38px' }}>{t`Pay yearly save 20%`}</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box pt={5} />

            <Box display={'flex'} flexWrap={'wrap'} flexDirection={isMobile ? 'column' : 'row'} alignItems={'center'} justifyContent={'center'} gap={isMobile ? '20px' : '40px'} width={'100%'} height={'100%'}>
                {
                    packages.map((value) => (
                        <PackageItem item={value} isYearlyPackage={!isMonthly} isFree={value.isFree} onSelectClick={() => {
                            window.open(t`google_link`, '_blank')
                        }} />
                    ))}
            </Box>
        </Box>
    </div>
}

type PackageItemProps = {
    item: TPackageItem,
    isYearlyPackage: boolean,
    isFree: boolean,
    onSelectClick: () => void
}

const PackageItem: React.FC<PackageItemProps> = (props) => {
    const isMobile = useMediaQuery('(max-width: 900px)');

    const {
        item,
        isYearlyPackage,
        isFree,
        onSelectClick
    } = props;

    return <Paper
        onClick={() => { }}
        elevation={0}
        sx={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: 'space-between',
            height: '100%',
            width: '80%',
            maxWidth: "290px",
            minHeight: isMobile ? 'auto' : '420px',
            borderRadius: '20px',
            padding: '10px 16px 16px 20px',
            boxShadow: '0px 4px 20px -2px #3232470A, 0px 0px 1px 0px #0C1A4B08'
        }}>
        <Box>
            <Typography fontSize={'40px'} fontWeight='700'>{item.icon}</Typography>
            <Typography fontSize={'20px'} fontWeight='700'>{item.packageName}</Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'baseline'}>
                {isYearlyPackage && !isFree &&
                <Typography pr={'5px'} fontSize={'32px'} fontWeight='500' sx={{textDecoration: "line-through", color: '#979797'}}>{item.packagePrice}</Typography>
                }
                <Typography pr={'5px'} fontSize={'32px'} fontWeight='700'>{isYearlyPackage ? item.discountedPackagePrice : item.packagePrice}</Typography>
                <Typography fontSize={'15px'} fontWeight='700'>{t`per month`}</Typography>
            </Box>
            {item.discountDescription && isYearlyPackage &&
                <Box bgcolor={'#3BAD49'} sx={{ borderRadius: '20px', display: 'inline-flex' }}>
                    <Typography p={'5px'} pl={'15px'} pr={'15px'} color='#ffffff' fontSize={'13px'} fontWeight='800'>{item.discountDescription}</Typography>
                </Box>
            }
            <Box p={'5px'} />
            <Typography fontSize={'15px'} fontWeight='800'>{item.additionalDescription}</Typography>
            {item.additionalDescription && <Box p={'5px'} />}
            {item.packageDescription.map((value) =>
                <Box display={'flex'} flex={'column'} alignItems={'center'} sx={{ pb: '10px' }}>
                    <CircleIcon sx={{ height: "7px", width: "7px", pr: '5px' }} />
                    <Typography variant='body1'>{value}</Typography>
                </Box>
            )}
        </Box>
        <Box p={'20px'} />
        <StyledButton sx={{ bgcolor: '#000000' }} variant='contained' onClick={onSelectClick}>
            <Typography fontSize={'14px'} fontWeight='700'>{t`*Try for free`}</Typography>
        </StyledButton>
    </Paper>

}

export default PricingSection;